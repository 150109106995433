import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FaChevronDown } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";
import "./Faq.scss"

const Faq = () => {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const redirectDomain = determineAppEnvironment();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="faq-page">
            <div className="ezBannerContainer">
                <img src={`${redirectDomain}/public/images/banners/faq.jpg`} className="ezStaticBannerImg" alt="FAQ" />
            </div>

            <div className="ezClockerBasicContentContainer">
                <button onClick={() => navigate("/")} className="headerButton">Return to the ezClocker main page</button>
                {/*--- desktop code ---*/}
                <div className="faq-desktop">
                    <div className="faq-section">
                        <h1 className="faq-question">What is ezClocker and how does it work?</h1>
                        <hr />
                        <p className="faq-answer">
                            ezClocker is time tracking and scheduling software for small businesses available as a website or
                            mobile application.
                            <br />
                            <br />
                            <h2 className="video-heading">Please check out this video for an overview</h2>
                        </p>
                        <embed
                            src="https://www.youtube.com/embed/Rxl_9DJARLI"
                            wmode="transparent"
                            className="video-container"
                            allow="autoplay; encrypted-media; picture-in-picture"
                            title="Employee Time Tracking and Scheduling Software - ezClocker"
                            frameBorder={0}
                            allowFullScreen></embed>
                    </div>

                    <div className="faq-section">
                        <h1 className="faq-question">How much does it cost and are there any hidden fees?</h1>
                        <hr />
                        <p className="faq-answer">
                            You can find our subscription plans on our pricing page <Link to="/pricing">here</Link>. There are no hidden fees, what is listed
                            is what you will pay.
                        </p>
                    </div>

                    <div className="faq-section">
                        <h1 className="faq-question">Who pays for the subscription? Do my employees have to pay?</h1>
                        <hr />
                        <p className="faq-answer">
                            No, only the employer pays the monthly subscriptions. Any employees added will be covered by the
                            employer’s payment up to the limit allowed
                            by
                            the subscription.
                        </p>
                    </div>

                    <div className="faq-section">
                        <h1 className="faq-question">How do I pay for ezClocker?</h1>
                        <hr />
                        <p className="faq-answer">
                            You can pay for ezClocker in a few ways:
                            <ul>
                                <li>You can sign in to your Employer account on the website, navigate to the Account page, and subscribe
                                    to a plan.</li>
                                <li>You can subscribe using your Apple iTunes account from within the ezClocker app on the iPhone.
                                </li>
                                <li>You can subscribe using your Google Play account through the ezClocker app on your Andriod phone.
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="faq-section">
                        <h1 className="faq-question">Why don't I see my employees' time entries on my side when they clock in?</h1>
                        <hr />
                        <p className="faq-answer">
                            This could be caused by:
                            <ul>
                                <li>The employee is using the wrong app, they are using ezClocker Personal when they should be using the
                                    ezClocker Business
                                    app. Or,</li>
                                <li>The employee did not accept the invite email and created their own account which is not tied to your
                                    account. Ask the
                                    employee if they accepted the invite email and followed the signup process. Or,</li>
                                <li>If you are using the app, check the date period at the top of the timesheet screen. Do the dates
                                    include the one you
                                    are trying to view?.</li>
                            </ul>
                        </p>
                    </div>
                    <div className="faq-section">
                        <h1 className="faq-question">I see my employee clocked in/out but the map says no GPS information available. What could cause this?
                        </h1>
                        <hr />
                        <p className="faq-answer">
                            The employee could be using the wrong app, if they have an iPhone they need to use the app ezClocker
                            Business, not ezClocker Personal.
                        </p>
                    </div>

                    <div className="faq-section">
                        <h1 className="faq-question">How do I delete my account on ezClocker?</h1>
                        <hr />
                        <p className="faq-answer">
                            <ul>
                                <li> If you are an employer then sign into ezclocker.com, click the Account button and on the Account
                                    page select the Delete
                                    Account button.</li>
                                <li> If you are an employee you will not be able to delete your account. Only employers can delete
                                    employees' accounts</li>
                            </ul>
                        </p>
                    </div>

                    <div className="faq-section">
                        <h1 className="faq-question">Why do I get an access denied message when I try to sign into ezClocker?</h1>
                        <hr />
                        <p className="faq-answer">
                            <ul>
                                <li> You either entered the wrong email or password. First, try to change the password by selecting the
                                    forgot password button
                                    on the sign in page</li>
                                <li> If you don't get a reset password email in your inbox or spam folder then that means your email is
                                    not in our system.
                                    Contact support@ezclocker.com to resolve this issue. Otherwise, resetting the pas0sword should fix
                                    the problem.</li>
                            </ul>
                        </p>
                    </div>

                    <div className="faq-section">
                        <h1 className="faq-question">How do I get help for my ezClocker account?</h1>
                        <hr />
                        <p className="faq-answer">
                            You can contact ezClocker by visiting our <Link to="/contact">contact page</Link>.
                        </p>
                    </div>
                </div>

                {/*--- mobile code ---*/}
                <div className="faq-mobile">
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<FaChevronDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h1 className="faq-question">What is ezClocker and how does it work?</h1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p className="faq-answer">
                                ezClocker is time tracking and scheduling software for small businesses available as a website or
                                mobile application.
                                <br />
                                <br />
                                <h2 className="video-heading">Please check out this video for an overview</h2>
                            </p>
                            <embed
                                src="https://www.youtube.com/embed/j4BWohINBY4"
                                wmode="transparent"
                                className="video-container"
                                allow="autoplay; encrypted-media; picture-in-picture"
                                title="Employee Time Tracking and Scheduling Software - ezClocker"
                                frameBorder={0}
                                allowFullScreen></embed>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<FaChevronDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h1 className="faq-question">How much does it cost and are there any hidden fees?</h1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p className="faq-answer">
                                You can find our subscription plans on our pricing page <Link to="/pricing" className="pricing-link">here</Link>
                                . There are no hidden fees, what is listed
                                is what you will pay.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<FaChevronDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h1 className="faq-question">Who pays for the subscription? Do my employees have to pay?</h1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p className="faq-answer">
                                No, only the employer pays the monthly subscriptions. Any employees added will be covered by the
                                employer’s payment up to the limit allowed
                                by
                                the subscription.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<FaChevronDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h1 className="faq-question">Why don't I see my employees' time entries on my side when they clock in?</h1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p className="faq-answer">
                                This could be caused by:
                                <ul>
                                    <li>The employee is using the wrong app, they are using ezClocker Personal when they should be using the
                                        ezClocker Business
                                        app. Or,</li>
                                    <li>The employee did not accept the invite email and created their own account which is not tied to your
                                        account. Ask the
                                        employee if they accepted the invite email and followed the signup process. Or,</li>
                                    <li>If you are using the app, check the date period at the top of the timesheet screen. Do the dates
                                        include the one you
                                        are trying to view?.</li>
                                </ul>
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary
                            expandIcon={<FaChevronDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h1 className="faq-question">I see my employee clocked in/out but the map says no GPS information available. What could cause this?
                            </h1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p className="faq-answer">
                                The employee could be using the wrong app, if they have an iPhone they need to use the app ezClocker
                                Business, not ezClocker Personal.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                        <AccordionSummary
                            expandIcon={<FaChevronDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h1 className="faq-question">How do I delete my account on ezClocker?</h1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p className="faq-answer">
                                <ul>
                                    <li> If you are an employer then sign into ezclocker.com, click the Account button and on the Account
                                        page select the Delete
                                        Account button.</li>
                                    <li> If you are an employee you will not be able to delete your account. Only employers can delete
                                        employees' accounts</li>
                                </ul>
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                        <AccordionSummary
                            expandIcon={<FaChevronDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h1 className="faq-question">Why do I get an access denied message when I try to sign into ezClocker?</h1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p className="faq-answer">
                                <ul>
                                    <li> You either entered the wrong email or password. First, try to change the password by selecting the
                                        forgot password button
                                        on the sign in page</li>
                                    <li> If you don't get a reset password email in your inbox or spam folder then that means your email is
                                        not in our system.
                                        Contact support@ezclocker.com to resolve this issue. Otherwise, resetting the pas0sword should fix
                                        the problem.</li>
                                </ul>
                            </p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                        <AccordionSummary
                            expandIcon={<FaChevronDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h1 className="faq-question">How do I get help for my ezClocker account?</h1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p className="faq-answer">
                                You can contact ezClocker by visiting our <Link to="/contact" className="contact-link">contact page</Link>.
                            </p>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <button onClick={() => navigate("/")} className="headerButton">Return to the ezClocker main page</button>
            </div>
        </div>
    )
}

export default Faq;
