/** @format */

export const industryNavigationMenu = [
    {
        title: 'Janitorial',
        link: '/time-tracking/janitorial-cleaning-services-gps/index.html',
    },
    {
        title: 'Catering',
        link: '/time-tracking/catering-food-services-gps/index.html',
    },
    {
        title: 'Landscape Lawn Care',
        link: '/time-tracking/landscape-lawn-care-service-gps/index.html',
    },
    {
        title: 'Home Care',
        link: '/time-tracking/homecare-app/index.html',
    },
    {
        title: 'Construction',
        link: '/time-tracking/construction-gps/index.html',
    },
    {
        title: 'Property Management',
        link: '/time-tracking/property-management-gps/index.html',
    },
    {
        title: 'Other Small Business',
        link: '/time-tracking/small-business-gps/index.html',
    },
    {
        title: 'Freelancers',
        link: '/time-tracking/time-clock-for-freelancers/index.html',
    },
];
