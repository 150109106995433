// TODO: Migrate the determineAppEnvironment to load from a configuration file
export const determineAppEnvironment = () => {
    const currentUrl = window.location.href;

    if (currentUrl.includes('localhost:3000')) {
        return 'https://dev.ezclocker.com';
    } else if (currentUrl.includes('localhost:8080')) {
        return 'http://localhost:8080';
    } else if (currentUrl.includes('dev.ezclocker.com')) {
        return 'https://dev.ezclocker.com';
    } else if (currentUrl.includes('qal.ezclocker.com')) {
        return 'https://qal.ezclocker.com';
    } else if (currentUrl.includes('e2e.ezclocker.com')) {
        return 'https://e2e.ezclocker.com';
    } else if (currentUrl.includes('prf.ezclocker.com')) {
        return 'https://prf.ezclocker.com';
    } else if (currentUrl.includes('stage.ezclocker.com')) {
        return 'https://stage.ezclocker.com';
    } else if (currentUrl.includes('stg.ezclocker.com')) {
        return 'https://stg.ezclocker.com';
    } else if (currentUrl.includes('orange.ezclocker.com')) {
        return 'https://orange.ezclocker.com';
    } else if (currentUrl.includes('green.ezclocker.com')) {
        return 'https://green.ezclocker.com';
    } else if (currentUrl.includes('blue.ezclocker.com')) {
        return 'https://blue.ezclocker.com';
    } else if (currentUrl.includes('old.ezclocker.com')) {
        return 'https://old.ezclocker.com';
    } else if (currentUrl.includes('prd.ezclocker.com')) {
        return 'https://prd.ezclocker.com';
    } else if (currentUrl.includes('ezclocker.com')) {
        return 'https://ezclocker.com';
    } else if (currentUrl.includes('eznovatech.com')) {
        return 'https://eznovatech.com';
    } else if (currentUrl.includes('zclocker.com')) {
        return 'https://zclocker.com';
    }

    return 'https://ezclocker.com';
};
