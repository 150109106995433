import React, { useState } from 'react';
import Button from '@mui/material/Button';
import './Contact-footer.scss';
import { FaChevronDown, FaChevronUp, FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";
import '../../assets/styles/ez-border.scss';
import '../../assets/styles/ez-color.scss';
import '../../assets/styles/ez-margin.scss';
import '../../assets/styles/ez-fonts.scss';
import { ezclockerMenuItems } from './aboutEzclocker-navigation';
import { industryNavigationMenu } from './industry-footer-navigation-menu';
import { productNavigationMenu } from './product-footer-navigation-menu';
import { Link } from 'react-router-dom';

const ContactFooter = () => {
    const redirectDomain = determineAppEnvironment();
    const [aboutEzclockerDropDown, setAboutEzclockerDropDown] = useState(false);
    const [productDropDown, setProductDropDown] = useState(false);
    const [industriesDropdown, setIndustriesDropdown] = useState(false);

    return (
        <footer
            className='contact-footer'>
            <div
                className="footer-links-section">
                <div
                    className="non-responsive">
                    <div>
                        <ul
                            className="collapse"
                            id="aboutfoot">
                            {ezclockerMenuItems.map(
                                (about, index) => {
                                    return (
                                        <li
                                            key={index}>
                                           <Link className="footer-links" to={about.type === "relative" ? `${redirectDomain}${about.link}`
                                                : about.link}>{about.title}</Link>
                                        </li>);
                                })}
                        </ul>
                    </div>
                    <div className="margin-left-30">
                        <ul
                            className="collapse"
                            id="productfoot">
                            {productNavigationMenu.map(
                                (productMap, index) => {
                                    return (
                                        <li
                                            key={index}>
                                           <Link className="footer-links" to={productMap.link}>{productMap.title}</Link>
                                        </li>);
                                })}
                        </ul>
                    </div>
                    <div
                        className="margin-left-30">
                        <ul
                            className="collapse"
                            id="indusfoot">
                            {industryNavigationMenu.map(
                                (industry, index) => {
                                    return (
                                        <li
                                            key={index}>
                                            <Button
                                                variant="text"
                                                className="footer-link"
                                                onClick={() => window.location.href = `${redirectDomain}${industry.link}`}>
                                                {industry.title}
                                            </Button>
                                        </li>);
                                })}
                        </ul>
                    </div>
                </div>
                <div
                    className="responsive">
                    <div
                        className="footer-responsive-main-link color-dull-white">
                        <Button
                            variant="text"
                            className="responsive-links-button background-color-transparent color-dull-white border-none font-size-20 font-weight-bold"
                            onClick={() => setAboutEzclockerDropDown((prev) => !prev)}>
                            About ezClocker
                        </Button>
                        {!aboutEzclockerDropDown && (
                            <FaChevronDown
                                className="faChevron"
                                onClick={() => setAboutEzclockerDropDown((prev) => !prev)} />
                        )}
                        {aboutEzclockerDropDown && (
                            <FaChevronUp
                                className="faChevron"
                                onClick={() => setAboutEzclockerDropDown((prev) => !prev)} />
                        )}
                    </div>
                    <ul
                        className={`${aboutEzclockerDropDown
                            ? 'ezclocker-dropdown-show'
                            : 'ezclocker-dropdown-hide'}`}>
                        {ezclockerMenuItems.map(
                            (about, index) => {
                                return (
                                    <li key={index}>
                                        <Link className="footer-links" to={about.type === "relative" ? `${redirectDomain}${about.link}`
                                                : about.link}>{about.title}</Link>
                                    </li>);
                            })}
                    </ul>
                    <div
                        className="footer-responsive-main-link color-dull-white">
                        <Button
                            variant="text"
                            className="responsive-links-button background-color-transparent border-none color-dull-white font-size-20 font-weight-bold"
                            onClick={() => setProductDropDown((prev) => !prev)}>
                            Product
                        </Button>
                        {!productDropDown && (
                            <FaChevronDown
                                className="faChevron"
                                onClick={() => setProductDropDown((prev) => !prev)} />
                        )}
                        {productDropDown && (
                            <FaChevronUp
                                className="faChevron"
                                onClick={() => setProductDropDown((prev) => !prev)} />
                        )}
                    </div>
                    <ul
                        className={`${productDropDown
                            ? 'product-dropdown-show'
                            : 'product-dropdown-hide'}`}>
                        {productNavigationMenu.map(
                            (product, index) => {
                                return (
                                    <li
                                        key={index}>
                                        <Link className="footer-links" to={product.link}>{product.title}</Link>
                                    </li>);
                            })}
                    </ul>
                    <div
                        className="footer-responsive-main-link color-dull-white">
                        <Button
                            variant="text"
                            className="responsive-links-button background-color-transparent border-none color-dull-white font-size-20 font-weight-bold"
                            onClick={() => setIndustriesDropdown((prev) => !prev)}>
                            Industries
                        </Button>
                        {!industriesDropdown && (
                            <FaChevronDown
                                className="faChevron"
                                onClick={
                                    () => setIndustriesDropdown((prev) => !prev)
                                } />
                        )}
                        {industriesDropdown && (
                            <FaChevronUp
                                className="faChevron"
                                onClick={
                                    () => setIndustriesDropdown((prev) => !prev)
                                } />
                        )}
                    </div>
                    <ul
                        className={`${industriesDropdown
                            ? 'industries-dropdown-show'
                            : 'industries-dropdown-hide'}`}>
                        {industryNavigationMenu.map(
                            (industry, index) => {
                                return (
                                    <li
                                        key={index}>
                                        <Button
                                            variant="text"
                                            className="footer-link"
                                            onClick={() => window.location.href = `${redirectDomain}${industry.link}`}>
                                            {industry.title}
                                        </Button>
                                    </li>);
                            })}
                    </ul>
                </div>
                <div
                    className="social-networks margin-left-30">
                    <Button
                        variant="text"
                        className="twitter footer-link"
                        onClick={() => window.location.href= 'https://twitter.com/ezClocker'}>
                        <FaTwitter />
                    </Button>
                    <Button
                        variant="text"
                        className="facebook footer-link"
                        onClick={() => window.location.href= 'https://www.facebook.com/ezClocker/'}>
                        <FaFacebook />
                    </Button>
                    <Button
                        variant="text"
                        className="google footer-link"
                        onClick={() => window.location.href= 'https://www.linkedin.com/company/ezclocker/'}>
                        <FaLinkedin />
                    </Button>
                    <div
                        className="contact-us-button-container">
                        <Link to="/contact">
                        <Button
                            id="EzEzContactUsButton"
                            className="contact-us-button"
                            >
                            Contact us
                        </Button>
                        </Link>
                    </div>
                </div>
                <div
                    className="margin-left-30">
                    <div
                        className="apple-store-social-networks">
                        <Button
                            variant="text"
                            className="footer-link"
                            onClick={() => window.location.href = 'https://itunes.apple.com/us/developer/eznova-technologies-llc/id800807200'}>
                            <img
                                className="appleAppStoreBadge color-dull-white"
                                id="appleAppStoreBadge"
                                alt="Download from Apple App Store"
                                src="https://s3.amazonaws.com/com.ezclocker.images/marketing/apple-appstore.svg" />
                        </Button>
                    </div>
                    <div
                        className="google-play-social-networks">
                        <Button
                            variant="text"
                            className="footer-link"
                            onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.ezclocker'}>
                            <img
                                alt="Get it on Google Play"
                                src="https://play.google.com/intl/en_us/badges/images/badge_new.png" />
                        </Button>
                    </div>
                </div>
            </div>
            <div
                className="footer-copyright color-white background-color-dark-dull-blue">
                <p>
                    6010 W. Spring Creek Pkwy Plano, TX 75024 Suite G
                </p>
                <p>
                    © 2014-2022 ezNova Technologies LLC. All Rights Reserved.
                </p>
            </div>
        </footer>
    );
};

export default ContactFooter;
