import React from 'react';
import '../../ezclocker-colors.scss';
import DesktopNavigationMenu from './DesktopNavigationMenu/DesktopNavigationMenu';
import MobileNavigationMenu from './MobileNavigationMenu/MobileNavigationMenu';
import './NavigationMenu.scss';

export default class NavigationMenu extends React.Component {
    render() {
        return (
            <div
                className="navigation-bar" >
                <div
                    className="navigation-bar-content">
                    <div
                        className="desktop-navigation-menu">
                        <DesktopNavigationMenu />
                    </div>
                    <div className="mobile-navigation-menu">
                        <MobileNavigationMenu />
                    </div>
                </div>
            </div>);
    }
}
