import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CancellationPolicy from '../CancellationPolicy/CancellationPolicy';
// import '../../ezclocker-colors.scss';
import Contact from '../Contact/Contact';
import { Landing } from '../Landing/Landing';
import Pricing from '../Pricing/Pricing';
import Privacy from '../Privacy/Privacy';
import Security from '../Security/Security';
import TermsOfService from '../TermsOfService/TermsOfService';
import BrowserInfo from '../BrowserInfo/BrowserInfo';
import Faq from '../Faq/Faq';
import PageNotFound from '../PageNotFound/PageNotFound';

const IndexRoute = () => {
    return (
        <Routes>
            <Route index path="/" element={<Landing />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/tos" element={<TermsOfService />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/security" element={<Security />} />
            <Route exact path="/cancellation_policy" element={<CancellationPolicy />} />
            <Route exact path="/browser-info" element={<BrowserInfo />} />
            <Route exact path="/faq" element={<Faq/>}/>
            <Route exact path="/*" element={<PageNotFound/>}/>
        </Routes>);
}

export default IndexRoute;
