import React, { useEffect, useState } from 'react';
import "./Pricing.scss";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, Container, Grid, Typography } from '@mui/material';
import PricingFooter from '../Pricing-footer/Pricing-footer';
import { FaCheck, FaChevronDown } from 'react-icons/fa';
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";

const Pricing = () => {

    const [expanded, setExpanded] = useState(false);
    const [activeButton, setActiveButton] = useState(1)
    const plans = {
        basic: { monthly: "$12", annual: "$10" },
        standard: { monthly: "$30", annual: "$25" },
        premium: { monthly: "$60", annual: "$50" },
    }
    const totalPrice = {
        basic: "$120",
        standard: "$300",
        premium: "$600"
    }


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleButtonClick = (index) => {
        setActiveButton(index)
    }

    const redirectDomain = determineAppEnvironment();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="pricingPage">
            <Box id="plans">
                <Container>
                    <Grid container>
                        <Grid item xs={12} id="intro-text-col">
                            <h3>The ezClocker subscription plans allow you to grow your subscription with your business</h3>
                        </Grid>
                        <Grid item xs={12} id="intro-sub-text-col">
                            <Grid container className="intro-row" justifyContent="center">
                                <Grid item xs={12}>
                                    <p id="startPlanText">Start today with our<strong> 30 day free </strong>trial</p>
                                </Grid>
                                <Grid item xs={12} id="try-button-col">
                                    <Button className="ez-marketing-go-button" id="try-now"
                                        onClick={() => window.location.href = `${redirectDomain}/public/signup.html`}
                                    >Try ezClocker Now!</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <ButtonGroup color='primary' aria-label='contained primary button group' className='plan-btns-group'>
                                        <Button className={activeButton === 0 ? 'active' : ''} onClick={() => handleButtonClick(0)}>Monthly</Button>
                                        <Button className={activeButton === 1 ? 'active' : ''} onClick={() => handleButtonClick(1)}>Annual</Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container id="planRow" justifyContent={'center'}>
                        <Grid item x={12} id="monthly">
                            <Grid container direction={{ sm: "column", md: "row" }} spacing={4} justifyContent={'center'}>
                                <Grid item md={4}>
                                    <div className="card" id="card">
                                        <div className="card-header">
                                            <div className="text-center plan-name">
                                                <h2>Basic</h2>
                                                <h3>
                                                    {activeButton === 1 && (
                                                        <span className="strike-through">{plans.basic.monthly}</span>
                                                    )}
                                                    {activeButton === 0 ? plans.basic.monthly : plans.basic.annual} /mo
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card-block">
                                            <div className="card-title" align="left">
                                                <h5>Up to 15 employees</h5>
                                            </div>
                                            <ul>
                                                {activeButton === 1 && (
                                                    <li><span>{totalPrice.basic} a year</span></li>
                                                )}
                                                <li><span>Web and mobile access</span></li>
                                                <li><span>Employee scheduling</span></li>
                                                <li><span>GPS verification</span></li>
                                                <li><span>Export Pay Periods to CSV file</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={4}>
                                    <div className="card" id="card">
                                        <div className="card-header">
                                            <div className="text-center plan-name">
                                                <h2>Standard</h2>
                                                <h3>
                                                    {activeButton === 1 && (
                                                        <span className="strike-through">{plans.standard.monthly}</span>
                                                    )}
                                                    {activeButton === 0 ? plans.standard.monthly : plans.standard.annual} /mo
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card-block">
                                            <div className="card-title" align="left">
                                                <h5>Up to 30 employees</h5>
                                            </div>
                                            <ul>
                                                {activeButton === 1 && (
                                                    <li><span>{totalPrice.standard} a year</span></li>
                                                )}
                                                <li><span>Web and mobile access</span></li>
                                                <li><span>Employee scheduling</span></li>
                                                <li><span>GPS verification</span></li>
                                                <li><span>Export Pay Periods to CSV file</span></li>
                                                <li><span>Job Assignments</span></li>
                                                <li><span>Overtime Reports</span></li>
                                                <li><span>Payroll Integrations</span></li>
                                                <li><span>Time Off</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item md={4}>
                                    <div className="card" id="card">
                                        <div className="card-header">
                                            <div className="text-center plan-name">
                                                <h2>Premium</h2>
                                                <h3>
                                                    {activeButton === 1 && (
                                                        <span className="strike-through">{plans.premium.monthly}</span>
                                                    )}
                                                    {activeButton === 0 ? plans.premium.monthly : plans.premium.annual} /mo
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card-block">
                                            <div className="card-title" align="left">
                                                <h5>Up to 100 employees</h5>
                                            </div>
                                            <ul>
                                                {activeButton === 1 && (
                                                    <li><span>{totalPrice.premium} a year</span></li>
                                                )}
                                                <li><span>Web and mobile access</span></li>
                                                <li><span>Employee scheduling</span></li>
                                                <li><span>GPS verification</span></li>
                                                <li><span>Export Pay Periods to CSV file</span></li>
                                                <li><span>Job Assignments</span></li>
                                                <li><span>Overtime Reports</span></li>
                                                <li><span>Payroll Integrations</span></li>
                                                <li><span>Time Off</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className="bg-light" id="features">
                <Container>
                    <Grid container spacing={2} justifyContent={'center'}>
                        <Grid item className="sect-header">
                            <h2>Features Included in All Plans</h2>
                        </Grid>
                    </Grid>
                    <Grid container spacing={{ xs: 0, md: 2 }} justifyContent={'center'} className="features-row">
                        <Grid item xs={12} md={4}>
                            <ul>
                                <li><FaCheck color="green" /><span className="feature-text">Web and mobile
                                    access</span></li>
                                <li><FaCheck color="green" /><span className="feature-text">Time Clock
                                    Kiosk</span></li>
                                <li><FaCheck color="green" /><span className="feature-text">GPS verification
                                    map</span></li>
                                <li><FaCheck color="green" /><span className="feature-text">Clock in/out
                                    notifications</span></li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ul>
                                <li><FaCheck color="green" /><span className="feature-text">Export to
                                    .csv</span></li>
                                <li><FaCheck color="green" /><span className="feature-text">Employee
                                    scheduling</span></li>
                                <li><FaCheck color="green" /><span className="feature-text">Time entries
                                    audit</span></li>
                                <li><FaCheck color="green" /><span className="feature-text">Customer
                                    support</span></li>
                            </ul>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box id="faq">
                <Container>
                    <Grid container justifyContent={'center'}>
                        <Grid item className="sect-header">
                            <h2>Frequently asked Questions</h2>
                        </Grid>
                    </Grid>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                            expandIcon={<FaChevronDown color='#0f4777' />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className='accordion-title'>How does the 30 day free trial work?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                You can try out ezClocker for 30 days completely free. No credit
                                card required if you are subscribing via the website and you can add as many
                                employees as you like. Support will also be included in the free trial in case you
                                have questions or need help getting setup.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<FaChevronDown color='#0f4777' />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className='accordion-title'>Do I have to install anything?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Our software is cloud-based so there is nothing to install.
                                Simply create an account, add employees and let them clock in/out. You have access
                                to your data using any device: computer, iPad, iPhone, or Android.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<FaChevronDown color='#0f4777' />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className='accordion-title'>How does the GPS feature work?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                When employees clock in or out using ezClocker the app captures
                                the GPS location of the employees. You as the employer can view the GPS information
                                on your phone or computer on an easy to view map screen. Please note that ezClocker
                                does not track the location of your employees while they are working, only when they
                                press the clock in or clock out buttons.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary
                            expandIcon={<FaChevronDown color='#0f4777' />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography className='accordion-title'>How do I get started?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Great question! simply visit our ezClocker homepage, enter your
                                business name, email, password and sign up. To read a step by step guide on how to
                                register and add employees please read&nbsp;<a
                                    href="//s3.amazonaws.com/com.ezclocker.static/tutorials/ezClocker_signup_process.pdf">
                                    <strong>this document</strong>
                                </a>.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Grid container id="gotmoreq" textAlign="center">
                        <Grid item xs={12}>
                            <h5>Got more questions?</h5>
                        </Grid>
                        <Grid item xs={12}>
                            <p>Send us an email to:&nbsp;<a href="mailto:info@ezclocker.com"><strong>info@ezclocker.com</strong></a></p>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <PricingFooter />
        </div>
    );
}

export default Pricing;