/** @format */

export const aboutNavigationItems = [
    {
        key: 'EzFAQ',
        title: 'FAQ',
        link: '/faq',
        type: 'absolute',
    }
];
