import { useEffect, useState } from 'react';
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";

/**
    React GA4 Module
    Github: https://github.com/unrealmanu/ga-4-react
 */
import GA4React from "ga-4-react";

let ga4React = null;

/**
    @class
    @extends {React.Component}
    @description
    Initializes the Google G4 analytics for Landing V2
    -----------------------------------------------------------------
    Import with:
        import { EzGoogleG4 } from '/Google/EzGoogleG4'
    -----------------------------------------------------------------
    Use:
        <EzGoogleG4/>
    -----------------------------------------------------------------
 */
export default function EzGoogleG4() {
    const [redirectDomain] = useState(determineAppEnvironment);

    const [preProduction] = useState(
        () => {
            switch (redirectDomain) {
                case 'http://localhost:8080':
                case 'https://dev.ezclocker.com':
                case 'https://qal.ezclocker.com':
                case 'https://e2e.ezclocker.com':
                case 'https://prf.ezclocker.com':
                    // Pre-production environment
                    return true;
                case 'https://stage.ezclocker.com':
                case 'https://stg.ezclocker.com':
                case 'https://orange.ezclocker.com':
                case 'https://green.ezclocker.com':
                case 'https://blue.ezclocker.com':
                case 'https://old.ezclocker.com':
                case 'https://prd.ezclocker.com':
                case 'https://ezclocker.com':
                case 'https://eznovatech.com':
                case 'https://zclocker.com':
                default:
                    // Production environment
                    return false;
            }
        });

    const [g4Id] = useState(
        () => {
            return preProduction
                // Pre-production enviornments
                ? 'G-YJ6VF6K2ST'
                // Production environments
                : 'G-DMMT6VL084';
        });

    ga4React = new GA4React(g4Id);

    useEffect(
        () => {
            async function initializeGA4React() {
                try {
                    await ga4React.initialize();
                } catch (err) {
                    console.error('Google Analytics not available (probably due to an ad or tracking blocker).');
                    console.error(err);
                }
            }

            if (null == ga4React) {
                initializeGA4React();
            }
        },
        []);

    return (null);
}
