import { ClickAwayListener } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { determineAppEnvironment } from "../../EzEnviornmentInfo/EzEnviornmentInfo";
import '../../../ezclocker-colors.scss';
import { FeaturesSubMenuItems } from '../FeatureSubMenu';
import './DesktopNavigationMenu.scss';

/**
 * @class
 * @extends {React.Component}
 * @description
 * Desktop type displays navigation menu
 */
export default class DesktopNavigationMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectDomain: determineAppEnvironment(),
            featuresDropDown: false
        };
    }

    showFeaturesDropDown() {
        this.setState({
            featuresDropDown: true
        });
    }

    hideFeaturesDropDown() {
        this.setState({
            featuresDropDown: false
        });
    }

    handleOnClickAway = function () {
        this.hideFeaturesDropDown();
    }.bind(this);

    render() {
        return (
            <ClickAwayListener onClickAway={this.handleOnClickAway}>
                <nav
                    className="navigation">
                    <ul
                        className="navigation-menu menu-dropdown"
                        onClick={this.showFeaturesDropDown.bind(this)}>
                        FEATURES
                        <div
                            className={
                                `${this.state.featuresDropDown
                                    ? 'nav-sub-menu-options-show background-color-white'
                                    : 'nav-sub-menu-options-hide'
                                }`}>
                            {FeaturesSubMenuItems.map(
                                (subMenu, index) => {
                                    return (
                                        <li className="sub-menu-item" key={subMenu.id}>
                                            <a
                                                className="sub-menu-item-link"
                                                href={`${this.state.redirectDomain}${subMenu.link}`}>
                                                {subMenu.title}
                                            </a>
                                        </li>);
                                })}
                        </div>
                    </ul>
                    <ul
                        className="navigation-menu">
                        <Link
                            className="navigation-menu-link"
                            to="/pricing">
                            PRICING
                        </Link>
                    </ul>
                    <ul
                        className="navigation-menu">
                        <Link
                            className="navigation-menu-link"
                            to="/contact">
                            CONTACT
                        </Link>
                    </ul>
                    <ul
                        className="navigation-menu">
                        <a
                            className="navigation-menu-link"
                            href={`${this.state.redirectDomain}/public/sign-in`}>
                            SIGN IN
                        </a>
                    </ul>
                </nav >
            </ClickAwayListener>);
    }
}
