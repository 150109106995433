import { ClickAwayListener } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { determineAppEnvironment } from "../../EzEnviornmentInfo/EzEnviornmentInfo";
import '../../../ezclocker-colors.scss';
import { FeaturesSubMenuItems } from '../FeatureSubMenu';
import './MobileNavigationMenu.scss';

/**
 * @class
 * @extends {React.Component}
 * @description
 * Mobile displays navigation menu
 */
export default class MobileNavigationMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectDomain: determineAppEnvironment(),
            mainMenuDropDown: false,
            featuresDropDown: false,
            mainMenuAnchorEl: React.useState < null | HTMLElement > (null),
            mainMenuOpen: false,
            featuresMenuAnchorEl: React.useState < null | HTMLElement > (null),
            featuresMenuOpen: false
        };
    }

    handleNavigationMenuClick = function(event) {
        this.setState({
            mainMenuAnchorEl: event.currentTarget,
            mainMenuOpen: !this.state.mainMenuOpen
        });
    }.bind(this);

    handleNavigationMenuClose = function() {
        this.setState({
            mainMenuAnchorEl: null,
            mainMenuOpen: false
        });
    }.bind(this);

    handleFeaturesMenuClick = function(event) {
        this.setState({
            featuresMenuAnchorEl: event.currentTarget,
            featuresMenuOpen: true
        });
    }.bind(this);

    handleFeaturesMenuClose = function() {
        this.setState({
            featuresMenuAnchorEl: null,
            featuresMenuOpen: false
        });
    }.bind(this);

    render() {
        return (
            <ClickAwayListener
                onClickAway={this.handleNavigationMenuClose}>
                <div
                    className="mobile-navigation">
                    <Button
                        id="EzMobileNavigationButton"
                        className="mobile-navigation-button"
                        aria-controls={
                            this.state.mainMenuOpen
                                ? 'MobileNavigationMenu'
                                : undefined}
                        aria-haspopup="true"
                        aria-expanded={
                            this.state.mainMenuOpen
                                ? 'true'
                                : undefined}
                        onClick={this.handleNavigationMenuClick}>
                        <FaBars
                            className="mobile-navigation-button">
                        </FaBars>
                    </Button>
                    <Menu
                        id="MobileNavigationMenu"
                        aria-labelledby="EzMobileNavigationButton"
                        anchorEl={this.state.mainMenuAnchorEl}
                        open={this.state.mainMenuOpen}
                        onClose={this.handleNavigationMenuClose}
                        keepMounted
                        MenuListProps={{
                            'aria-labelledby': 'EzMobileNavigationButton'
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}>
                        <MenuItem
                            onClick={this.handleNavigationMenuClose}>
                            <Link
                                className="navigation-menu-item-link"
                                id="ezNavPricing"
                                to="/pricing">
                                PRICING
                            </Link>
                        </MenuItem>
                        <MenuItem
                            onClick={this.handleNavigationMenuClose}>
                            <Link
                                className="navigation-menu-item-link"
                                id="ezNavContact"
                                to="/contact">
                                CONTACT
                            </Link>
                        </MenuItem>
                        <MenuItem
                            onClick={this.handleNavigationMenuClose}>
                            <a
                                className="navigation-menu-item-link"
                                id="ezNavSignIn"
                                href={`${this.state.redirectDomain}/public/sign-in`}>
                                SIGN IN
                            </a>
                        </MenuItem>
                        <Divider />
                        <label
                            className="features-menu-title">
                            FEATURES
                        </label>
                        {FeaturesSubMenuItems.map(
                            (subMenu, index) => {
                                return <MenuItem
                                    key={subMenu.id}
                                    onClick={this.handleNavigationMenuClose}>
                                    <a
                                        className="navigation-menu-item-link"
                                        id="ezNavSignIn"
                                        href={`${this.state.redirectDomain}${subMenu.link}`}>
                                        {subMenu.title}
                                    </a>
                                </MenuItem>
                            })}
                    </Menu>
                </div>
            </ClickAwayListener>);
    }
}
