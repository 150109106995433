import React from 'react';
import './RaitingsSection.scss';
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";


export default class RaitingsSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectDomain: determineAppEnvironment(),
        };
    }

    render() {
        return (
            <div
                className="raitings-section">
                <div
                    className="ratings-cards">
                    <div
                        className="rating-card">
                        <div
                            className="rating-card-image-container">
                            <img
                                className="rating-card-capterra-image"
                                src={`${this.state.redirectDomain}/public/images/newLandingPage/capterra_bw.svg`}
                                alt="Capterra" />
                        </div>
                        <div
                            className="rating-card-image-container">
                            <img
                                className="rating-card-star-image"
                                src={`${this.state.redirectDomain}/public/images/newLandingPage/stars_4.8.svg`}
                                alt="Stars" />
                        </div>
                        <div
                            className="rating-card-text">
                            4.8 of 5 on Capterra
                        </div>
                    </div>
                    <div
                        className="rating-card">
                        <div
                            className="rating-card-image-container">
                            <img
                                className="rating-card-google-play-image"
                                src={`${this.state.redirectDomain}/public/images/google/google-play-badge.svg`}
                                alt="Google Play" />
                        </div>
                        <div
                            className="rating-card-image-container">
                            <img
                                className="rating-card-star-image"
                                src={`${this.state.redirectDomain}/public/images/newLandingPage/stars_4.6.svg`}
                                alt="Stars" />
                        </div>
                        <div
                            className="rating-card-text">
                            4.6 of 5 on Google Play
                        </div>
                    </div>
                    <div
                        className="rating-card">
                        <div
                            className="rating-card-apple-app-store-image-container">
                            <img
                                className="rating-card-apple-app-store-image"
                                src={`${this.state.redirectDomain}/public/images/apple/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg`}
                                alt="ratings" />
                        </div>
                        <div
                            className="rating-card-image-container">
                            <img
                                className="rating-card-star-image"
                                src={`${this.state.redirectDomain}/public/images/newLandingPage/stars_4.8.svg`}
                                alt="Stars" />
                        </div>
                        <div
                            className="rating-card-text">
                            4.8 of 5 on Apple App Store
                        </div>
                    </div>
                </div>
            </div>);
    }
}
