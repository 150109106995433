import React, { useEffect, useState, useCallback } from 'react';
import './TestimonialsCarousel.scss';
import '../../assets/styles/ez-fonts.scss';
import '../../assets/styles/ez-border.scss';
import '../../assets/styles/ez-color.scss';

const TestimonialsCarousel = ({ testimonialObject }) => {
    const imageSetNumber = 1;
    const [slideIndex, setSlideIndex] = useState(imageSetNumber);
    const [status, setStatus] = useState(true);

    const nextSlide = useCallback(
        (index) => {
            if (!index) {
                setSlideIndex(slideIndex < testimonialObject.length ? slideIndex + 1 : 1);
            } else {
                setSlideIndex(index + 1);
            }
        },
        [slideIndex, testimonialObject]
    );

    useEffect(() => {
        let counterId;

        if (status) {
            counterId = setTimeout(nextSlide, 7000);
        }

        return () => {
            clearTimeout(counterId);
        };
    }, [slideIndex, nextSlide, status]);

    const setSlide = (index) => {
        setSlideIndex(index + 1);
    };

    const nextSlideOnClickAndStopTimer = (index) => {
        setStatus(false);
        clearTimeout(nextSlide);
        setSlide(index);
    };

    return (
        <div
            className="testimonials-carousel">
            <div
                className="testimonial-content">
                {testimonialObject.map(
                    (data, index) => {
                        return (
                            <div
                                key={index}
                                className={index + 1 === slideIndex
                                    ? 'testimonial-text-container active-anim'
                                    : 'slide'}>
                                <p
                                    className="testimonial-first-paragraph">
                                    {data.firstLine}
                                </p>
                                <p
                                    className="testimonial-second-paragraph">
                                    {data.secondLine}
                                </p>
                                <p
                                    className="testimonial-third-paragraph">
                                    {data.thirdLine}
                                </p>
                            </div>
                        );
                    })}
                <div className="rect-div">
                    {[0, 1, 2, 3, 4].map((data, index) => {
                        return (
                            <div
                                key={index}
                                className={index + 1 === slideIndex
                                    ? 'rect rect-dark background-color-orange'
                                    : 'rect rect-light background-color-white'}
                                onClick={() => nextSlideOnClickAndStopTimer(index)}
                            ></div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

TestimonialsCarousel.propTypes = {};

TestimonialsCarousel.defaultProps = {};

export default TestimonialsCarousel;
