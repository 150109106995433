import React from 'react';
import Button from '@mui/material/Button';
import '../../ezclocker-colors.scss';
import './DevicesSection.scss';
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";

export default class DevicesSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectDomain: determineAppEnvironment(),
        };
    }

    render() {
        return (
            <div
                className="devices-section"
                style={{ backgroundColor: '#ffffff' }}>
                <div
                    className="employee-time-tracking-options-text"
                    style={{}}>
                    Employee Time Tracking Options
                </div>
                <div
                    className="ezclocker-device-sections-div">
                    <div
                        className="ezclocker-device-section">
                        <div
                            className="device-image-container">
                            <img
                                className="device-web-image"
                                src={`${this.state.redirectDomain}/public/images/landing/ezc_web_asset_device_Laptop.svg`}
                                alt="Web" />
                        </div>
                        <h1
                            className="ezclocker-device-header">
                            Web
                        </h1>
                        <p
                            className="ezclocker-device-main-description-div margin-30">
                            EzClocker online time tracking is perfect for your office staff. The employees can clock in/out via ezClocker website, enter
                            notes and view schedules. As the employer you can use it to export timesheets, create schedules, or adjust employee time
                            punches. You can audit all time entry changes.
                        </p>
                        <div
                            className="devices-learn-more-button-container">
                            <Button
                                variant="text"
                                className="devices-learn-more-button"
                                onClick={() => window.location.href = `${this.state.redirectDomain}/time-tracking/small-business-gps/index.html`}>
                                Learn more
                            </Button>
                        </div>
                    </div>
                    <div
                        className="ezclocker-device-section">
                        <div
                            className="device-image-container">
                            <img
                                className="device-mobile-image"
                                src={`${this.state.redirectDomain}/public/images/newLandingPage/ezc_web_asset_device_iPhone.svg`}
                                alt="Mobile" />
                        </div>
                        <h1
                            className="ezclocker-device-header">
                            Mobile App
                        </h1>
                        <p
                            className="ezclocker-device-main-description-div margin-30">
                            With a tap of a button your employees can clock in or clock out using their smartphone devices,
                            view their timesheet, or find when their next shift will be. When an employee clocks in or out we
                            capture the GPS location so you the employer can verify they were at the
                            correct job site on time.

                        </p>
                        <div
                            className="devices-learn-more-button-container">
                            <Button
                                variant="text"
                                className="devices-learn-more-button"
                                onClick={() => window.location.href = `${this.state.redirectDomain}/time-tracking/mobile-time-clock-apps/index.html`}>
                                Learn more
                            </Button>
                        </div>
                    </div>
                    <div
                        className="ezclocker-device-section">
                        <div
                            className="device-image-container">
                            <img
                                className="device-kiosk-image"
                                src={`${this.state.redirectDomain}/public/images/landing/ezc_web_asset_device_Tablet.svg`}
                                alt="Kiosk" />
                        </div>
                        <h1
                            className="ezclocker-device-header">
                            Kiosk App
                        </h1>
                        <p
                            className="ezclocker-device-main-description-div margin-30">
                            If you want to use one device for your employees at a job site then you can use our ezClocker Kiosk App built for the tablets.
                            Employees can sign in using a 4 digit PIN number. The employer can also use the app to view employees’ timesheets, adjust time
                            entries and email time clocks.
                        </p>
                        <div
                            className="devices-learn-more-button-container">
                            <Button
                                variant="text"
                                className="devices-learn-more-button"
                                onClick={() => window.location.href = `${this.state.redirectDomain}/time-tracking/portable-time-clock-kiosk-app/index.html`}>
                                Learn more
                            </Button>
                        </div>
                    </div>
                </div>
            </div >);
    }
}
