/** @format */

export const marketingNavigationItems = [
    {
        key: "EzMarketing_TimeTracking_GPSMobileApp",
        title: 'How GPS Works',
        link: '/time-tracking/gps-mobile-app/index.html',
        type: 'absolute'
    },
    {
        key: "EzMarketing_TimeTracking_MobileTimeClockApps",
        title: 'Mobile Apps',
        link: '/time-tracking/mobile-time-clock-apps/index.html',
        type: 'absolute'
    },
    {
        key: "EzMarketing_TimeTracking_Integrations",
        title: 'Integrations',
        link: '/time-tracking/integrations/index.html',
        type: 'absolute'
    },
];
