import { useEffect } from "react";
import { Link } from "react-router-dom";
import { determineAppEnvironment } from "../EzEnviornmentInfo/EzEnviornmentInfo";
import "./TermsOfService.scss";

const TermsOfService = () => {

    const redirectDomain = determineAppEnvironment();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="tosPage">
            <div className="tosBannerContainer">
                <img className="ezBannerImage" src={`${redirectDomain}/public/images/banners/tos.jpg`} alt="." />
            </div>
            <div className="ezMarginTop10 ezPad8">
                <div className="ezWhiteBox8">
                    <Link to="/">Return to Main Page </Link>
                </div>
            </div>
            <div className="ezGridContent ezMarginTop10 ezPad10">
                <h1 className="ezHeading">ezClocker Terms and Conditions</h1>
                <h4>Effective as of 05/01/2022</h4>
                <h3 className="ezSubHeadings">1. Terms</h3>
                <p>
                    Enova Technologies, LLC d/b/a ezClocker (ezClocker) operates a website and mobile application which
                    provides services to businesses to track the time of employees and independent contractors, among other
                    things (the Service or Services). Anyone accessing and using (collectively, You) the ezClocker website
                    or mobile application (collectively, the ezClocker Software), agrees to be bound by these Terms and
                    Conditions of Use, all applicable laws, and regulations and agrees to be responsible for compliance with
                    any applicable federal, state or local laws.
                </p>
                <p>
                    Employers using the ezClocker Software agree as follows:
                </p>
                <p className="ezLeftMargin_20">
                    (a) to instruct their employees on the proper use of the ezClocker Software and
                    the proper tracking of his/her time worked each day;
                </p>
                <p className="ezLeftMargin_20">
                    (b) to require each employee to accurately track and report his/her time worked
                    on the ezClocker Software;
                </p>
                <p className="ezLeftMargin_20">
                    (c) if the employer has authorized ezClocker to make an automatic thirty (30)
                    minute time deduction as an unpaid lunch break for hourly employees that have not clocked out for lunch,
                    the employer agrees to inform each hourly employee that, in the event the employee does not clock out
                    for a lunch break, a thirty (30) minute time deduction for an unpaid lunch break will be automatically
                    deducted from the total amount of time worked that day by the employee;
                </p>
                <p className="ezLeftMargin_20">
                    (d) to instruct each employee to check his/her time records for accuracy on the
                    ezClocker Software and report any errors to the employer, including, without limitation, any errors
                    related to an automatic time deduction for an unpaid lunch break; and
                </p>
                <p className="ezLeftMargin_20">
                    (e) to immediately correct any errors in an employee's time records.
                </p>
                <p>
                    You agree that ezClocker is not responsible for the accuracy of the hours worked as reported by each
                    employee or for other information or data input by You on the ezClocker Software.
                </p>
                <p>
                    If You do not agree with any of these terms, You are prohibited from using or accessing the ezClocker
                    Software. The materials contained in the ezClocker Software are protected by applicable copyright and
                    trademark law.
                </p>
                <h3 className="ezSubHeadings">2. Use License</h3>
                <ol>
                    <li>
                        Permission is granted to temporarily download one copy of the materials (information or software)
                        on the ezClocker Software for personal, non-commercial transitory viewing only. This is the grant of
                        a license, not a transfer of title, and under this license, you may not:
                        <ol>
                            <li>modify or copy the materials or create derivative works therefrom;</li>
                            <li>use the materials for any commercial purpose, or for any public display (commercial or
                                non-commercial);</li>
                            <li>attempt to decompile or reverse engineer any software contained on the ezClocker Software;
                            </li>
                            <li>remove any copyright or other proprietary notations from the materials; or</li>
                            <li>
                                transfer the materials to another person or &quot;mirror&quot; the materials on any other
                                server.
                            </li>
                        </ol>
                    </li>
                    <li>
                        This license shall automatically terminate if You violate any of these restrictions and may be
                        terminated by ezClocker at any time. Upon terminating Your viewing of these materials or upon the
                        termination of this license, You must destroy any downloaded materials in Your possession whether in
                        electronic or printed format.
                    </li>
                </ol>
                <h3 className="ezSubHeadings"> 3. Ownership of Information</h3>
                <p>
                    All data input into the ezClocker Software is owned by the employers that use the ezClocker Software
                    (Employers or Employer) and not by employees (similar to employee timesheets, which are owned by an
                    employer and not an employee). Any employee using the ezClocker Software acknowledges and understands
                    that he/she has no right to the information he/she submits/inputs via the ezClocker Software directly
                    from ezClocker and that ezClocker cannot provide him/her with such information as this information is
                    the property of the Employer. Employer acknowledges that if ezClocker is served with a subpoena for such
                    information it must comply as required by applicable law. Notwithstanding the above, an employee will
                    have access to view and copy (via email by the employee to the employee) records of his/her timesheets
                    via the ezClocker Software while the employee is an authorized user.
                </p>
                <h3 className="ezSubHeadings">4. Opt-in</h3>
                <p>
                    By accepting these terms and conditions You agree that ezClocker may communicate with You at any time
                    regarding Your account and that ezClocker may send other communications to You, including newsletters
                    and surveys. You will have the option of unsubscribing from the latter, but not the former.
                </p>
                <h3 className="ezSubHeadings">5. Fees and Cancellation</h3>
                <p>
                    Employers using the ezClocker Services agree to pay the fees (the Fees) set out on the ezClocker
                    website, which may be increased from time to time upon 30 days notice. If an employer has one employee
                    or less, the fees are waived. All Employers signing up will receive a free 30-day trial of the ezClocker
                    Service that begins on the date the Employer signs up for the Service and ends on the 30 days thereafter
                    (the Initial Trial Period). If an Employer does not cancel the Service before the end of the Initial
                    Trial Period, it will be charged and agrees to pay the Fees. Fees will be billed to the credit card on
                    file. After the Initial Trial Period, the Service will renew automatically every 30 days unless
                    canceled. The Services may be canceled at any time. All cancellations will be effective as of the next
                    30-day renewal date. There are no refunds for partial 30-day periods. Please refer to the ezClocker
                    website for instructions on how to cancel the Service. Generally, if you have subscribed to the Service
                    using Apple iTunes or Google Play you will need to access those sites to cancel the Service, and links
                    to those sites are available on the ezClocker mobile application.
                </p>
                <h3 className="ezSubHeadings">6. User Comments</h3>
                <p>
                    You may not post content on the ezClocker website that infringes or violates someone else's rights or
                    otherwise violates the law. ezClocker may remove any content or information that You post if it believes
                    that such information violates these terms and conditions or ezClocker's policies. You shall not post
                    anyone's sensitive information, such as names, social security numbers, or other sensitive or personal
                    information. If You violate these terms and conditions, ezClocker may disable your account with no
                    advance notice to you.
                </p>
                <h3 className="ezSubHeadings"> 7. Disclaimer</h3>
                <ol>
                    <li>
                        ezClocker has no obligation to maintain data input by users on the ezClocker Software. Employers
                        are required by law to keep certain information for specific periods of time. Employers acknowledge
                        and agree that they are not relying on ezClocker to store or maintain such data and that it is the
                        employers ' sole responsibility to export their data from the ezClocker Software on a regular basis
                        and maintain it for purposes of compliance with their legal obligations.
                    </li>
                    <li>
                        The Services and materials provided by ezClocker are provided &quot;as is&quot;. ezClocker makes no
                        warranties, expressed or implied, and hereby disclaims and negates all other warranties, including
                        without limitation, implied warranties or conditions of merchantability, fitness for a particular
                        purpose, or non-infringement of intellectual property or other violation of rights. Further,
                        ezClocker does not warrant or make any representations concerning the accuracy, likely results, or
                        reliability of the use of the materials or Services it provides or otherwise relating to such
                        materials or Services on any sites linked to the ezClocker Software.
                    </li>
                    <li>
                        ezClocker disclaims any and all liability for adverse employment decisions made by employers based
                        on data provided to the employer by ezClocker.
                    </li>
                </ol>
                <h3 className="ezSubHeadings"> 8. Limitations &amp; Indemnification</h3>
                <p>
                    In no event shall ezClocker or its suppliers be liable for any direct, indirect, consequential, or
                    exemplary damages (including, without limitation, damages for loss of data or profit, or due to business
                    interruption,) arising out of the use or inability to use the materials or Services provided by
                    ezClocker's, even if ezClocker or an ezClocker authorized representative has been notified orally or in
                    writing of the possibility of such damage. Because some jurisdictions do not allow limitations on
                    implied warranties, or limitations of liability for consequential or incidental damages, these
                    limitations may not apply to You.
                </p>
                <p>
                    To the extent permitted by law, the employer agrees to indemnify, defend and hold harmless ezClocker and
                    its respective members, managers, owners, officers, directors, employers, agents, and representatives
                    (the &ldquo;Indemnified Party&rdquo;) from and against any and all claims by employees or any other
                    third party claims, actions liabilities, losses and damages (collectively, a &ldquo;Claim&rdquo;) to the
                    extent caused by or arising out of any intentional or negligent act or omission by the employer in the
                    course of the employer 's use or access to the ezClocker Software, including but not limited to
                    violations of all state and federal wage and hour laws (including the Fair Labor Standards Act and the
                    Texas Labor Code and other similar state-specific statutes); federal and state employment discrimination
                    and anti-retaliation laws, whistleblower laws, tort claims, breach of contract claims, and claims for
                    court costs and attorneys' fees.
                </p>
                <h3 className="ezSubHeadings">9. Revisions and Errata</h3>
                <p>
                    The materials appearing on the ezClocker Software could include technical, typographical, or
                    photographic errors. ezClocker does not warrant that any of the materials on the ezClocker Software are
                    accurate, complete, free from errors, or current. ezClocker may make changes to the materials contained
                    on the ezClocker Software at any time without notice. ezClocker does not, however, make any commitment
                    to update the materials.
                </p>
                <h3 className="ezSubHeadings"> 10. Links</h3>
                <p>
                    ezClocker has not reviewed all of the sites linked to the ezClocker Software and is not responsible for
                    the contents of any such linked site. The inclusion of any link does not imply endorsement by ezClocker
                    of the site. Use of any such linked site is at the user&#39;s own risk.
                </p>
                <h3 className="ezSubHeadings"> 11. Site Terms of Use Modifications</h3>
                <p>
                    ezClocker may revise these terms and conditions at any time without notice. By using the ezClocker
                    Software You are agreeing to be bound by the then current version of these Terms and Conditions.
                </p>
                <h3 className="ezSubHeadings"> 12. Governing Law</h3>
                <p>
                    Any claim relating to the ezClocker Software or materials or Services provided by ezClocker shall be
                    governed by the laws of the State of Texas without regard to its conflict of law provisions. To the
                    extent any provision of the ezClocker Terms and Conditions conflicts with applicable state or local law,
                    the applicable law shall govern.
                </p>
                <h3 className="ezSubHeadings"> Privacy Policy </h3>
                <p>
                    Your privacy is very important to us. Accordingly, we have developed this Policy in order for You to
                    understand how we collect, use, communicate and disclose and make use of personal information. The
                    following outlines our privacy policy.
                </p>
                <ul>
                    <li>
                        ezClocker collects information from You to provide You with our Service. We will never sell, rent,
                        or loan Your information to anyone.
                    </li>
                    <li>
                        We will collect and use the information solely with the objective of providing the ezClocker
                        Service.
                    </li>
                    <li>
                        We will only retain personal information as long as necessary to provide the ezClocker Service.
                    </li>
                    <li>We will collect personal information by lawful and fair means. </li>
                    <li>
                        Personal data should be relevant to the purposes for which it is to be used, and, to the extent
                        necessary for those purposes, should be accurate, complete, and up-to-date.
                    </li>
                    <li>
                        We will protect personal information by reasonable security safeguards against loss or theft, as
                        well as unauthorized access, disclosure, copying, use, or modification.
                    </li>
                </ul>
            </div>
            <div className="ezMarginTop10 ezMarginBottom10">
                <div className="ezWhiteBox8">
                    <Link to="/">Return to Main Page </Link>
                </div>
            </div>
        </div>
    )
}

export default TermsOfService;
