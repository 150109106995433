import React from 'react';
import '../../ezclocker-colors.scss';
import EzClockerHeaderLogo from '../EzClockerHeaderLogo/EzClockerHeaderLogo';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import './PageHeader.scss';
import AppBar from '@mui/material/AppBar';


export default class PageHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: window.location.pathname,
            showMenu: true
        };
    }

    componentDidMount() {
        if(this.state.url?.includes('/tos') || this.state.url?.includes('/privacy') || this.state.url?.includes('/security') || this.state.url?.includes('/cancellation_policy')){
            this.setState({showMenu: false})
        }
    }

    render() {
        return (
                <AppBar
                    position="sticky">
                        <div
                            className="header-content">
                            <EzClockerHeaderLogo />
                            {this.state.showMenu && <NavigationMenu />}
                        </div>
                </AppBar>);
    }
}
