/** @format */

export const productNavigationMenu = [
    {
        title: 'Terms of Service',
        link: '/tos',
    },
    {
        title: 'Privacy',
        link: '/privacy',
    },
    {
        title: 'Security',
        link: '/security',
    },
    {
        title: 'Cancellation Policy',
        link: '/cancellation_policy',
    },
];
