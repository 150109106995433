export const FeaturesSubMenuItems = [
    {
        id: 'EzSubMenu_TimeSheets',
        title: 'Timesheets',
        link: '/time-tracking/timesheet-app/index.html',
    },
    {
        id: 'EzSubMenu_MobileApps',
        title: 'Mobile Apps',
        link: '/time-tracking/mobile-time-clock-apps/index.html',
    },
    {
        id: 'EzSubMenu_KioskApps',
        title: 'Kiosk App',
        link: '/time-tracking/portable-time-clock-kiosk-app/index.html',
    },
    {
        id: 'EzSubMenu_GPSVerification',
        title: 'GPS Verification',
        link: '/time-tracking/gps-mobile-app/index.html',
    },
    {
        id: 'EzSubMenu_JobTracking',
        title: 'Job Tracking',
        link: '/time-tracking/job-tracking-software/index.html',
    },
    {
        id: 'EzSubMenu_EmployeeScheduling',
        title: 'Employee Scheduling',
        link: '/time-tracking/employee-scheduling-software/index.html',
    },
    {
        id: 'EzSubMenu_PayrollIntegration',
        title: 'Payroll Integration',
        link: '/time-tracking/integrations/index.html',
    },
];
