/** @format */

import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import '../../assets/styles/ez-color.scss';
import '../../assets/styles/ez-margin.scss';
import '../../assets/styles/ez-padding.scss';
import './CustomerImageCarousel.scss';

/**
 * Props: {
 *  customers: {array}
 * }
 */
export default class CustomerImageCarousel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            slideIndex: this.props.displayCount
        }
    }

    nextSlide = function () {
        let updatedSlideIndex = 0;

        if (this.state.slideIndex !== this.props.customers.length) {
            updatedSlideIndex = this.state.slideIndex + this.props.displayCount;
        } else if (this.state.slideIndex === this.props.customers.length) {
            updatedSlideIndex = this.props.displayCount;
        }

        this.setState({
            slideIndex: updatedSlideIndex
        });
    }.bind(this)

    prevSlide = function () {
        let updatedSlideIndex = 0;

        if (this.state.slideIndex !== this.props.displayCount) {
            updatedSlideIndex = this.state.slideIndex - this.props.displayCount;
        } else if (this.state.slideIndex === this.props.displayCount) {
            updatedSlideIndex = this.props.customers.length;
        }

        this.setState({
            slideIndex: updatedSlideIndex
        });
    }.bind(this)

    render() {
        return (
            <div
                className="customer-image-carousel" >
                <div
                    className="customer-images-container-slider">
                    <div
                        className="meet-our-customers-chevron">
                        <FaChevronLeft
                            onClick={this.prevSlide} />
                    </div>
                    {this.props.customers.map(
                        (customerInfo, index) => {
                            return (
                                <div
                                    key={customerInfo.id}
                                    className={
                                        this.state.slideIndex - index > 0 && this.state.slideIndex - index <= this.props.displayCount
                                            ? 'active-anim meet-our-customers-image-div'
                                            : 'slide meet-our-customers-image-div'}>
                                    <a
                                        href={customerInfo.link}
                                        target="_blank"
                                        rel="noreferrer">
                                        <img
                                            src={typeof customerInfo.src === 'string'
                                                ? customerInfo.src
                                                : customerInfo.src.default}
                                            alt={customerInfo.alt}
                                            className="meet-our-customers-image" />
                                    </a>
                                    {customerInfo.link
                                        ? (
                                            <div className="font-size-24 font-weight-bold">
                                                <a
                                                    className="font-size-24 font-weight-bold color-blue"
                                                    href={customerInfo.link}
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    {customerInfo.customerName}
                                                </a>
                                            </div>)
                                        : (
                                            <div
                                                className="font-size-24 font-weight-bold color-blue verdana-font-family">
                                                {customerInfo.customerName}
                                            </div>)}
                                    <div
                                        className="font-size-16 padding-top-10">
                                        {customerInfo.customerCompany}
                                    </div>
                                    <div
                                        className="font-size-16">
                                        {customerInfo.customerProfession}
                                    </div>
                                </div>
                            );
                        })}
                    <div
                        className="meet-our-customers-chevron">
                        <FaChevronRight
                            onClick={this.nextSlide} />
                    </div>
                </div>
            </div>);
    }
};
